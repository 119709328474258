import AxiosIntance from '../axios/axiosInstace';

export class CtacteApi {

    getCtacte(idCuentaPersona) {
        return AxiosIntance.get(`ctacte/${idCuentaPersona}`)
    }

    getTiposMovimientos(){
        return AxiosIntance.get(`ctacte/tiposMovimientos`)
    }

    addMovCtacte(movCtacte) {
        return AxiosIntance.post(`ctacte`, movCtacte)
    }

    updateMovCtaCte(movCtacte) {
        return AxiosIntance.put('ctacte', movCtacte)
    }

    movCtaCteFiltroPorFecha(body) {
        return AxiosIntance.post('ctacte/ctacteFiltroPorFecha', body)
    }

}