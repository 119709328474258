import { Grid, Typography } from '@material-ui/core';

// Import Componentes
import Controls from '../Forms/Controls'
import FormContainer from '../Forms/FormContainer';

// Custom hooks
import { useForm } from '../../hooks/useForm'
import useEnviarDatos from '../../hooks/useEnviarDatos';
import { CtacteApi } from '../../api/CtacteApi';

// Action combo select 
import { getTiposMovimientos } from '../../Redux/actions/ctacte/CtacteActionCreator';

const FromMovimientosCtacte = ({ width = '', data, recargarTabla, accionCancelar, funcSuccess,
    dataEdit = false }) => {
    const initialValue = {
        idtipomov: '',
        pagado: false,
        concepto: '',
        debe: '0',
        haber: '0',
        fecmov: '',
    }
    const {
        values,
        setValues,
        handleChangeForm,
        resetForm,
    } = useForm({
        ...data,
        ...initialValue,
        ...dataEdit

    });


    const { sendData: addMovCtacte } = useEnviarDatos({
        action: Object.entries(dataEdit).length ? new CtacteApi().updateMovCtaCte : new CtacteApi().addMovCtacte,
        dataSubmit: Object.entries(dataEdit).length ? { ...values, id: dataEdit.id } : values,
        textSucces: Object.entries(dataEdit).length ? 'Movimiento Actualizado' : 'Mov. Agregado',
        recargarTabla: recargarTabla,
        funcSuccess: funcSuccess
    })


    return (
        <FormContainer
            sizeTitle="h6"
            height="auto"
            width={width}
            resetForm={resetForm}
            LabelButtonSubmit={Object.entries(dataEdit).length ? "Actualizar Movimiento" : "Agregar Mov Cta.cte."}
            alignContent="center"
            title=""
            accionCancelar={accionCancelar}
            onSubmit={() => { addMovCtacte() }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><u>Tipo Movimiento:</u></Typography>
                    <Typography variant="subtitle2"><b>DEBITO ARANCEL:</b> Para debitos de aranceles de carrera</Typography>
                    <Typography variant="subtitle2"><b>HABER:</b> Para registros en el Haber</Typography>
                    <Typography variant="subtitle2"><b>DEBITO VARIOS:</b> Para debitos que NO generen intereses</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Controls.AutocompleteSelect
                        promSelectList={getTiposMovimientos()}
                        name="idtipomov"
                        label="Tipo de movimiento"
                        filtro='descripcion'
                        valorSalida="id"
                        valueautocomplete={values.idtipomov}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1"><u>Nota:</u>Si la opcion "Pagado" queda sin marcar este estara disponible para pagar desde el autogestion</Typography>
                    <Controls.Checkbox
                        value={!!values.pagado}
                        name='pagado'
                        onChange={handleChangeForm}
                        label="Pagado"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controls.DateField
                        inputProps={{ style: { fontSize: 14 } }}
                        name="fecmov"
                        title="Fecha Mov."
                        InputLabelProps={{
                            style: { fontSize: 14 }
                        }}
                        onChange={handleChangeForm}
                        value={values.fecmov}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controls.Input
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        multiline={true}
                        minRows={3}
                        maxRows={6}
                        label="Concepto"
                        name="concepto"
                        value={values.concepto}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6} >
                    <Controls.Input
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        label="Debe"
                        name="debe"
                        value={values.debe}
                        onChange={handleChangeForm}
                    />
                </Grid>
                <Grid item xs={6} >
                    <Controls.Input
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        label="Haber"
                        name="haber"
                        value={values.haber}
                        onChange={handleChangeForm}
                    />
                </Grid>
            </Grid>
        </FormContainer >

    )
}

export default FromMovimientosCtacte
