import React from 'react'
import { TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useIsMounted from '../../hooks/useIsMounted';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            width: '100%',
        },
    }));

    export default function Input(props) {
        const classes = useStyles()
    
        const { 
            multiline = false, 
            rows = 1,
            name, 
            label, 
            value, 
            error = null, 
            onChange, 
            type, 
            InputLabelProps, 
            verySmall = false, 
            onBlur, 
            size = 'small', 
            onKeyPress = ''
        } = props;
    
        // Manejador condicional para el Enter
        const handleKeyPress = (e) => {
            if (!multiline) {
                // Para inputs normales, prevenir Enter
                e.key === 'Enter' && e.preventDefault();
            }
            // Si es multiline, permite el comportamiento por defecto del Enter
        }
    
        return (
            <div className={classes.root}>
                <TextField
                    InputLabelProps={InputLabelProps}
                    type={type}
                    variant="outlined"
                    multiline={multiline}
                    rows={rows}
                    label={label}
                    name={name}
                    onKeyPress={onKeyPress || handleKeyPress}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    size={size}
                    {...props}
                    {...(error && { error: true, helperText: error })}
                />
            </div>
        )
    }